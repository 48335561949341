export default class FormMethods {
  constructor(_parm) {
    // formClear
    this.elmsFormClearBtn = Array.prototype.slice.call(document.querySelectorAll('[data-js-form-clear-btn]'))
    // inputClear
    this.AttrInputClear = 'data-js-input-clear-btn'
    this.elmsInputClearBtn = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrInputClear}]`))
    // checkToggleDisplay
    this.AttrCheckToggleDisplay = 'data-js-form-check-toggle-display'
    this.elmsCheckToggleDisplay = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrCheckToggleDisplay}]`))
    // radioToggleDisplay
    this.AttrRadioToggleDisplay = 'data-js-form-radio-toggle-display'
    this.elmsRadioToggleDisplay = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrRadioToggleDisplay}]`))
    // checkAll & checkAllRemove
    this.AttrCheckAll = 'data-js-form-check-all'
    this.elmsCheckAll = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrCheckAll}]`))
    this.AttrCheckAllRemove = 'data-js-form-check-all-remove'
    this.elmsCheckAllRemove = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrCheckAllRemove}]`))
    // toggleClassElmSelectValue
    this.elmsSelect = Array.prototype.slice.call(document.querySelectorAll('select.__edit'))
    // 税込金額計算
    this.AttrCalcAmountTax_input = 'data-js-calc-tax--input'
    this.AttrCalcAmountTax_tax = 'data-js-calc-tax--tax'
    this.AttrCalcAmountTax_output = 'data-js-calc-tax--output'
    this.elmsCalcAmountTax_input = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrCalcAmountTax_input}]`))
    this.elmsCalcAmountTax_tax = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrCalcAmountTax_tax}]`))
    // その他の検索条件を表示する
    this.AttrOtherTermToggle = 'data-other-term-toggle-btn'
    this.elmsOtherTermToggleBtn = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrOtherTermToggle}]`))
    // mtshr（明治大正昭和平成令和）形式で入力したら西暦に変換する
    this.AttrInputDateMtshr = 'data-js-date-mtshr'
    this.elmsInputDateMtshr = Array.prototype.slice.call(document.querySelectorAll(`[${this.AttrInputDateMtshr}]`))
  }

  /**
    * メインのフォームを一度でも編集してたら、ページ遷移時にアラート表示させる
    * （一覧では実行しない）
    */
  editInputAlert() {
    if(!location.pathname.match(/\/list$/)){
      const elmForm = document.querySelector('.wrapper > form.p-main-content') || false;
      if(!elmForm) return;
      const elmInputs = Array.prototype.slice.call(elmForm.querySelectorAll('input, select, textarea'));
      let isEdited = false;
      let isSubmit = false;

      elmInputs.forEach(_elmInput => {
        _elmInput.addEventListener('input', (_ev) => {
          isEdited = true;
        })
      });

      elmForm.addEventListener('submit', (_ev) => {
        isSubmit = true;
        const elmErrorInputs = Array.prototype.slice.call(elmForm.querySelectorAll('.__is-error'));
        if(elmErrorInputs.length > 0) isSubmit = false;
      })
      const elmSubmits = Array.prototype.slice.call(elmForm.querySelectorAll('button[onclick="submit()"]'));
        elmSubmits.forEach(_elmSubmit => {
         _elmSubmit.addEventListener('click', (_ev) => {
           isSubmit = true;
           const elmErrorInputs = Array.prototype.slice.call(elmForm.querySelectorAll('.__is-error'));
           if (elmErrorInputs.length > 0) isSubmit = false;
         })
      });
      window.onbeforeunload = (event) => {
        if(isEdited) {
          if(isSubmit) return;
          event = event || window.event;
          event.returnValue = '入力中のページから移動しますか？';
        }
        if(!isSubmit) {
          if(!isEdited) return;
          event = event || window.event;
          event.returnValue = '入力中のページから移動しますか？';
        }
      }
    }
  }

  /**
   * イベント付与
   */
  addEvent() {

    /**
     * フォームの入力内容及びエラー関係をリセット
     */
    (() => {
      if (this.elmsFormClearBtn.length === 0) return;
      this.elmsFormClearBtn.forEach(_elmClearBtn => {
        _elmClearBtn.addEventListener('click', (_ev) => {
          // 入力内容クリア
          _elmClearBtn.form.reset();
          Array.from(_elmClearBtn.form.elements).forEach(_elm => {
            if(_elm.tagName.toLowerCase() === 'input' && _elm.name != '_token' && !_elm.readOnly){
                console.log(_elm.type.toLowerCase());
                if (_elm.type.toLowerCase() == "checkbox" || _elm.type.toLowerCase() == "radio") {
                    _elm.checked = "";
                } else {
                    _elm.value = null;
                }
            }
          });
          _elmClearBtn.form.querySelectorAll('input[type="hidden"]').forEach(_elm => {
            if(_elm.name != '_token'){
                _elm.value = null;
            }
          })
          // 全エラーメッセージを最初に削除
          _elmClearBtn.form.querySelectorAll('.__error-message').forEach(_elm => {
            _elm.remove()
          })
          // 全inputのエラーclassを最初に削除
          _elmClearBtn.form.querySelectorAll('.__is-error').forEach(_elm => {
            _elm.classList.remove('__is-error')
            _elm.classList.remove('__is-ok')
          })
          // 選択を解除
          _elmClearBtn.form.querySelectorAll('.c-tag-select__tags-item').forEach(_elm => {
            _elm.querySelector('button').dispatchEvent(new Event('click'))
          })
        })
      })
    })();

    /**
     * inputの入力内容をリセット
     */
    (() => {
      if (this.elmsInputClearBtn.length === 0) return;
      let AttrInputClear =  this.AttrInputClear;
      $(document).on('click',`[${AttrInputClear}]`,function () {
          let _elmClearBtn = this;
          const getTargetElms = () => {
            const splitSelector = _elmClearBtn.getAttribute(AttrInputClear).split(',')
            return splitSelector.map(_targetSelector => {
              return document.querySelector(_targetSelector) || false;
            }).filter(Boolean);
          }
          getTargetElms().forEach(_targetElm => {
            _targetElm.value = '';
            $(_targetElm).change();
          });
        })
    })();

    /**
     * チェックをつけ外しするたびに、指定の要素の表示非表示を切り替える（1対1）
     */
    (() => {
      if (this.elmsCheckToggleDisplay.length === 0) return;
      this.elmsCheckToggleDisplay.forEach(_elmCheckToggleDisplay => {
        _elmCheckToggleDisplay.addEventListener('input', (_ev) => {
          const idTarget = _elmCheckToggleDisplay.getAttribute(this.AttrCheckToggleDisplay)
          const elmTarget = document.querySelector(idTarget)
          const targetStyleDisplay = window.getComputedStyle(elmTarget).display
          if (targetStyleDisplay === 'none') {
            elmTarget.style.display = 'block'
          } else {
            elmTarget.style.display = 'none'
          }
        })
      })
    })();

    /**
     * ラジオボタンにチェックをつけ外しするたびに、指定の要素の表示非表示を切り替える（多対多）
     */
    (() => {
      if (this.elmsRadioToggleDisplay.length === 0) return;
      const radioToggleDisplay = _elmRadio => {
        const idTarget = _elmRadio.getAttribute(this.AttrRadioToggleDisplay)
        // チェックしたinputに該当する要素
        const elmTarget = document.querySelector(idTarget)
        // 該当する要素と同階層の要素
        const elmsTargetAll = Array.prototype.slice.call(elmTarget.parentNode.children)
        elmsTargetAll.forEach(_elmTarget => {
          _elmTarget.style.overflow = 'hidden'
          _elmTarget.style.visibility = 'hidden'
          _elmTarget.style.height = '0'
        })
        elmTarget.style.visibility = 'visible'
        elmTarget.style.height = 'auto'
      }
      this.elmsRadioToggleDisplay.forEach(_elmRadioToggleDisplay => {
        _elmRadioToggleDisplay.checked ? radioToggleDisplay(_elmRadioToggleDisplay) : false;
        _elmRadioToggleDisplay.addEventListener('input', (_ev) => {
          radioToggleDisplay(_elmRadioToggleDisplay)
        })
      })
    })();

    /**
     * 全てのチェックを入れる / 外す
     */
    (() => {
      const checkAllToggle = (_inputName, _check_or_remove) => {
        const elmInputs = Array.prototype.slice.call(document.querySelectorAll(`input[name="${_inputName}"]`))
        elmInputs.forEach(_elmInput => {
          if (_check_or_remove) {
            _elmInput.checked = true
          } else {
            _elmInput.checked = false
          }
        })
      }
      if (this.elmsCheckAll.length === 0) {
        this.elmsCheckAll.forEach(_elmCheckAll => {
          _elmCheckAll.addEventListener('click', (_ev) => {
            const targetInputName = _elmCheckAll.getAttribute(this.AttrCheckAll)
            checkAllToggle(targetInputName, true)
          })
        })
      }
      if (this.elmsCheckAllRemove.length === 0) {
        this.elmsCheckAllRemove.forEach(_elmCheckAllRemove => {
          _elmCheckAllRemove.addEventListener('click', (_ev) => {
            const targetInputName = _elmCheckAllRemove.getAttribute(this.AttrCheckAllRemove)
            checkAllToggle(targetInputName, false)
          })
        })
      }
    })();

    /**
     * <select>の値がある場合、classを付与する
     */
    (() => {
      if (this.elmsSelect.length === 0) return;
      this.elmsSelect.forEach(_elmSelect => {
        _elmSelect.addEventListener('input', (_ev) => {
          if (_elmSelect.value !== '') {
            _elmSelect.classList.add('__selected')
          } else {
            _elmSelect.classList.remove('__selected')
          }
        })
      })
    })();

    /**
     * 税込金額を計算して表示させる
     * 計算するinputに`data-js-calc-amount-tax="#transaction-fees-result-1,#transaction-fees-tex-1"`のように付与
     * 属性値は`計算結果を表示させるinputのセレクター,税が何%かの値を持つinputのセレクター`の形式
     * 「税が何%かの値を持つinputのセレクター」は計算用だから表示はしなくても良い。つまり`<input type=hidden>`でも大丈夫
     */
    (() => {
      if (this.elmsCalcAmountTax_input.length === 0) return;
      const setValueCalcAmountTax = _elm => {
        const getKey = () => {
          const type = _elm.hasAttribute(this.AttrCalcAmountTax_input) ? 'input' : 'tax';
          return type === 'input'
            ? _elm.getAttribute(this.AttrCalcAmountTax_input)
            : _elm.getAttribute(this.AttrCalcAmountTax_tax);
        }
        const elmInput = document.querySelector(`[${this.AttrCalcAmountTax_input}="${getKey()}"]`)
        const elmTax = document.querySelector(`[${this.AttrCalcAmountTax_tax}="${getKey()}"]`)
        const elmOutput = document.querySelector(`[${this.AttrCalcAmountTax_output}="${getKey()}"]`)
        const calcResult = Math.floor(Number(elmInput.value * (1 + (elmTax.value / 100)))).toLocaleString()
        elmOutput.value = calcResult !== 'NaN' ? calcResult : '入力内容に誤りがあります';
      }
      this.elmsCalcAmountTax_input.forEach(_elm => {
        setValueCalcAmountTax(_elm) // ページ読み込み時
        _elm.addEventListener('input', (_ev) => {
          setValueCalcAmountTax(_elm) // 入力時
        })
      })
      this.elmsCalcAmountTax_tax.forEach(_elm => {
        _elm.addEventListener('input', (_ev) => {
          setValueCalcAmountTax(_elm) // 入力時
        })
      })
    })();

    /**
     * その他の検索条件を表示する
     * ボタンクリックで表示非表示を切り替え
     */
    (() => {
      if (this.elmsOtherTermToggleBtn.length === 0) return;
      this.elmsOtherTermToggleBtn.forEach(_elmBtn => {
        _elmBtn.addEventListener('click', (_ev) => {
          if(_elmBtn.getAttribute(this.AttrOtherTermToggle) === 'true'){
            _elmBtn.setAttribute(this.AttrOtherTermToggle, 'false');
            _elmBtn.querySelector('button').textContent = 'その他の検索条件を表示する';
          } else{
            _elmBtn.setAttribute(this.AttrOtherTermToggle, 'true');
            _elmBtn.querySelector('button').textContent = 'その他の検索条件を隠す';
          }
        })
      })
    })();

  // 現在未使用
    /**
     * mtshr（昭和平成令和）形式で入力したら西暦に変換する
     */
    // (() => {
    //   if (this.elmsInputDateMtshr.length === 0) return;
    //   this.elmsInputDateMtshr.forEach(_elmInput => {
    //     _elmInput.setAttribute('placeholder', 'yyyy/mm/dd')
    //     _elmInput.addEventListener('blur', (_ev) => {
    //       const value = _ev.target.value;
    //       // {mtshr}yy/mm/dd にマッチしたら
    //       if(value.match(/(^[mtshr]\d{1,2}|^[mtshr])\/\d{1,2}\/\d{1,2}$/)){
    //         const mtshr_yy = value.match(/(^[mtshr]\d{1,2}|^[mtshr])/)[0];
    //         const getChangeMtshrDate = _value => {
    //           const datas = {
    //             m: { // 明治
    //               minYearYYYY: 1868,
    //               maxYearYYYY: 1912,
    //               maxYearYY: 45
    //             },
    //             t: { // 大正
    //               minYearYYYY: 1912,
    //               maxYearYYYY: 1926,
    //               maxYearYY: 15
    //             },
    //             s: { // 昭和
    //               minYearYYYY: 1926,
    //               maxYearYYYY: 1989,
    //               maxYearYY: 64
    //             },
    //             h: { // 平成
    //               minYearYYYY: 1989,
    //               maxYearYYYY: 2019,
    //               maxYearYY: 31
    //             },
    //             r: { // 令和
    //               minYearYYYY: 2019,
    //               maxYearYYYY: 2099,
    //               maxYearYY: 81
    //             }
    //           };
    //           let result = _value;
    //           for (const _key in datas) {
    //             const _data = datas[_key];
    //             const ptnRegexp = new RegExp(`^${_key}(\\d{1,2})$|^${_key}$`);
    //             if(_value.match(ptnRegexp)){
    //               const minYear = _data.minYearYYYY; // 明治元年
    //               const maxYear = _data.maxYearYYYY; // 明治45年（最後の年）
    //               const replaceRegexp = new RegExp(`^${_key}`);
    //               const inputYear = Number(_value.replace(replaceRegexp, ''));
    //               const changeYYYY = () => {
    //                 if(inputYear <= 1) return minYear;
    //                 if(inputYear >= _data.maxYearYY) return maxYear;
    //                 return inputYear + minYear - 1;
    //               }
    //               result = changeYYYY();
    //               break;
    //             }
    //           }
    //           return result;
    //         }
    //         const changeYYYY = getChangeMtshrDate(mtshr_yy);
    //         _ev.target.value = value.replace(mtshr_yy, changeYYYY);
    //       }
    //     })
    //   })
    // })();

  } // end addEvent();

  init() {
    this.editInputAlert()
  　this.addEvent()
  }
}
